@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0px;
  padding: 0px;
  font-family: 'Roboto', sans-serif !important;
  scroll-behavior: smooth;
  color: #000 !important;

}
h1,h2,h3,h4,h5,h6{
  opacity: 1 !important;
}

h1 {
  opacity: 1 !important;
}

h2 {
  opacity: 1 !important;
}

h3 {
  opacity: 1 !important;
}

h4 {
  opacity: 1 !important;
}

h5 {
  opacity: 1 !important;
}

h6 {
  opacity: 1 !important;
}

#root {
  overflow-x: hidden;
}

/* ********MOBILE SIDE MENU ******** */
.mobile_menu {
  display: none;
}

.mobile_side ul li {
  width: 100%;
  padding-top: 55px;
  margin: 0 auto;
  transition: .5s;
  position: relative;
  list-style: none;
  padding-left: 0px !important;
  cursor: pointer;
  text-align: center;
}

.mobile_side {
  position: relative;
  transition: .5s;
  background: #F7F5F2;
  height: 100%;
  width: 80px;
  margin: 0;
}

.mobile_side ul {
  margin: 0 auto;
  padding: 0;
}

.mobile_side ul li:hover a {
  display: block;
  text-decoration: none;
  color: #000;
}

.mobile_side ul li a {
  font-family: 'Outfit', sans-serif;
  color: #000;
  border: 0px solid black;
  background: transparent;
  padding: 10px 20px;
  border-radius: 8px;
  display: block;
  transition: .5s;
}

.active {
  color: #8D0AE2 !important;
  font-weight: 700;
  transition: .2s;
}

.header_button:hover {
  color: white !important;
}

.css-viou3o-MuiAutocomplete-root {
  width: 100% !important;
}

/* =================================================================pricing======================================================== */
#pricing {
  position: relative;
  padding-bottom: 75px;
}

.price-card .price-ctb-cstom button {
  width: fit-content !important;
}

.cutom-margin-top {
  margin-top: 0px !important;
}
.land-scape-auth{
  display: none;
}
.extra-left-account{
  display: block;
}
.pricing_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(./images/Hero-BG-left.png);
  background-repeat: no-repeat;
  background-position: left;
  background-position: left;
  /* background-position-y: -379px; */
  top: -176px;
}

.pricing_heading {
  position: relative;
  text-align: center;
}

.pricing_heading h6 {
  font-size: 23px;
  color: #25408F;
  font-weight: 600;
}

.pricing_heading h2 {
  font-size: 45px;
  color: #182955;
  font-weight: 700;
  line-height: 59px;
}

.pricing_heading span {
  color: #8D0AE2;
  font-family: 'Outfit', sans-serif !important;
  font-size: 64px;
}

.pricing_card {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.card-1 {
  position: relative;
  background: radial-gradient(48.09% 112.23% at 93.56% 106.97%, #500680 0%, #8D0AE2 100%);
  border-radius: 20px;
  padding: 50px;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

.card-2 {
  position: relative;
  background: radial-gradient(48.09% 112.23% at 93.56% 106.97%, #500680 0%, #8D0AE2 100%);
  border-radius: 20px;
  padding: 50px;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

.card_content {
  position: absolute;
  top: 200px;
  color: #000;
  left: 4px;
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 5px 5px 5px rgb(0 0 0 / 50%);
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.price-card {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 20px;
}

.price-add {
  height: 245px;
  padding: 36px 33px;
  border-radius: 20px;
  background: radial-gradient(48.09% 112.23% at 93.56% 106.97%, #500680 0%, #8D0AE2 100%);
}

.price-add .chip-limit {
  padding-inline: 10px;
  padding-block: 6px;
  background-color: #1EC130;
  color: white;
  border-radius: 10px;
  font-weight: 500;
  width: fit-content;

}

.price-add .price-chip {
  display: flex;
  align-items: center;
}

.price-add .price-chip h1 {
  color: white;
  font-weight: 800;
  font-size: 45px;
}

.price-add .price-chip h6 {
  margin: 0px;
  color: white;
  font-weight: 800;
}

.ppd-det {
  padding: 30px 33px;
}

.price-add .price-chip del {
  color: white;
  font-weight: 600;
  font-size: 20px;
}

.price-add h5 {
  color: white;
  font-weight: 600;
  margin: 0;
  line-height: 140%;
}

.price-card .product-price-detail p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0;
  margin-left: 18px;
}

.ppd-det-pointers {
  padding-bottom: 16px;
}

.ppd-det-pointers :nth-child(:last-child) {
  padding-bottom: 0px;
}

.price-btn button {
  background-color: #9a00df;
  color: white;
  transition: 1s all;
  border: none;
  margin-bottom: 30px;
  cursor: pointer;
  border-radius: 5px;
  padding-block: 15px;
  padding-inline: 15px;
  position: relative;
  outline: none;
  font-weight: 800;
}

.price-btn button .animation-span-1 {
  background-color: #9a00df;
  height: 100%;
  width: 21%;
  position: absolute;
  right: 0px;
  z-index: 99;
  top: 0px;
  border-radius: 5px;

}

.btn-animation-arrow {
  margin-left: 10px;
  transition: 0.3s all;
}

.price-btn button:hover .btn-animation-arrow {
  transform: translate(15px, -15px);
  opacity: 0;
}

.price-btn button .animation-span-2 {
  background-color: #9a00df;
  height: 20px;
  z-index: 99;
  width: 100%;
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 5px;
}


.price-btn button:hover .btn-animation-arrow-2 {
  transform: translate(-13px, 0px);
  opacity: 1;
}

.btn-animation-arrow-2 {
  transform: translate(-27px, 16px);
  transition: 0.3s all;
  opacity: 0;
}

/* =================================================================pricing======================================================== */

/* =================================================================client review======================================================== */

#clientreview {
  padding-top: 150px;
}

#clientreview .main-client-card-start {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 20px;
  position: relative;
}

.client-top-image:hover {
  background-color: #e6c5f0;
  border-radius: 20px;
}

.client-top-image {
  width: 100%;
  border-radius: 20px;
  padding-inline: 10px;
  padding-block: 10px;
  height: auto;
  transition: 0.6s all;
  padding-bottom: 50px;
}

.profile-pic-client {
  display: flex;
  flex-direction: column;
  margin-top: -80px;
  transition: 0.6s all;
}

.client-top-image:hover .profile-pic-client {
  transform: translate(0px, -10px);
}

.profile-pic-client img {
  width: fit-content;
  height: fit-content;
}

.client-card-review-name h5 {
  font-weight: 600;
  font-size: 25px;
  margin: 0px;
  color: #182955;
}

.client-card-review-name h6 {
  color: #25408F;
}

.client-card-review-name .rating i {
  font-size: 25px;
  padding: 2px;
  color: #8D0AE2;
}

.comment-of-client i h6 {
  color: black;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: px;
}

.qoute-margin {
  position: absolute;
  right: 0px;
  bottom: -16px;
}

.client-top-image:hover .profile-crown {
  transform: scale(0.7, 0.7);
}

.profile-crown {
  transition: 0.5s all;
}




.price-btn-outinle button {
  background-color: white;
  border: 2px solid#9a00df;
  color: #9a00df;
  transition: 1s all;
  font-size: 14px;
  font-weight: 800;
  border-radius: 5px;
  font-weight: 800;
  position: relative;
  outline: none;
  padding: 16px 32px;
}

#clientreview .price-btn-outinle button:hover {
  background-color: white !important;
  color: #9a00df;
}

.price-btn-outinle button .animation-span-1 {
  background-color: white;
  height: 100%;
  width: 9%;
  position: absolute;
  right: 0px;
  z-index: 99;
  top: 0px;
  border-radius: 5px;

}

.price-btn-outinle .btn-animation-arrow {
  margin-left: 10px;
  transition: 0.3s all;
}

.price-btn-outinle button:hover .btn-animation-arrow {
  transform: translate(15px, -15px);
  opacity: 0;
}

.price-btn-outinle button .animation-span-2 {
  background-color: white;
  height: 17px;
  z-index: 99;
  width: 100%;
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 5px;
}


.price-btn-outinle button:hover .btn-animation-arrow-2 {
  transform: translate(-13px, 0px);
  opacity: 1;
}

.price-btn-outinle .btn-animation-arrow-2 {
  transform: translate(-27px, 16px);
  transition: 0.3s all;
  opacity: 0;
}

/* =================================================================client review======================================================== */

/* =================================================================Question======================================================== */











#question .question-heading h1 {
  font-size: 45px;
  font-weight: 700;
  color: #182955;
}

#question .question-heading span {
  font-size: 45px;
  font-weight: 700;
  color: #8D0AE2;
}

.ant-space-vertical {
  width: 100%;
}

.ant-space-item .ant-collapse {
  background-color: white;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  border: none !important;
  border-bottom: 0;
  border-radius: 20px;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: none !important;
}

.ant-collapse-header .ant-collapse-header-text {
  font-size: 30px;
  color: #25408F;
  font-weight: 700;
  padding: 15px;
}

.side_bar_top_bottom {
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 20px;
}

.ant-space-item {
  padding-top: 0px;
  padding-bottom: 20px;
}

.ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  transform: rotate(90deg) !important;
}

.ant-collapse-content {
  border-top: none !important;
}

.ant-collapse-content-box p {
  font-size: 20px !important;
  color: black !important;
  padding-left: 20px;
}

.ant-collapse-item:last-child>.ant-collapse-content {
  border-radius: 0 0 20px 20px !important;
}


/* =================================================================Question======================================================== */






.lprp {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* Header Section */
#header {
  font-family: 'Outfit', sans-serif;

  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0 10px 0;
  position: relative;
}

.burger {
  cursor: pointer !important;
  border: none !important;
  outline: none !important;
  display: none;
  justify-content: pointer;
  position: absolute;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 35px;
}

.burger:before {
  content: '';
  position: absolute;
  width: 30px;
  height: 2px;
  border-radius: 20px;
  background: #ffff;
  top: 0;
  left: 0;
  transform: translateY(-15px);
  transition: .5s;
}

.burger:after {
  content: '';
  border-radius: 20px;
  position: absolute;
  width: 30px;
  height: 2px;
  box-shadow: 0px -7px #fff;
  background: #ffff;
  top: 0;
  left: 0;
  transition: .5s;
}

.header.active .burger::before {
  transform: rotate(45deg);
}

.header.active .burger::after {
  transform: rotate(-45deg);
  box-shadow: none;

}

.overlay_menu {
  width: 0%;
  height: 0%;
  transition: .5s;
}

/* 
.header.active .overlay_menu img {
  filter: invert(1);
} */

.header.active .overlay_menu {
  transition: .5s;
  background: #8D0AE2 !important;
  height: 100% !important;
  width: 100% !important;
}

.header.active .mobile_navbar {
  height: 100vh;
  background: #8D0AE2;
}

.overlay_menu ul {
  transition: .5s;
  display: none;
}

.header.active .overlay_menu ul {
  transition: .5s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.header.active .header {
  display: none;
}

.header.active .overlay_menu li {
  color: #fff !important;
  list-style: none;
}

.header_btn {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 21px;
  font-weight: 800;
}

.header_btn :nth-child(1) {
  /* padding: 16px 16px; */
  width: 115px;
  height: 52px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8D0AE2;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  margin-top: 0;
  font-weight: 800;
  font-size: 14px;
  transition: .4s;
  cursor: pointer;
}


.header_details {
  padding-left: 21px;
}

.header_btn :nth-child(1):hover {
  width: 115px;
  height: 52px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #8D0AE2; */
  background-color: transparent;
  color: #8D0AE2;
  border: 2px solid #8D0AE2;
  border-radius: 5px;
  text-transform: uppercase;
  margin-top: 0;
  font-weight: 800;
  font-size: 14px;
  transition: .4s;
  cursor: pointer;
}

.header_btn :nth-child(1):focus {
  outline: none;
}

.header_btn :nth-child(2) {
  margin-top: 4px;
  margin-left: 20px;
  padding: 12px 20px;
  border: 2px solid #8D0AE2;
  background: transparent;
  color: #8D0AE2;
  transition: 0.3s all;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 800;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
}

.header_btn :nth-child(2):focus {
  outline: none;
}


.header_btn :nth-child(2):hover {
  margin-top: 4px;
  margin-left: 20px;
  padding: 12px 20px;
  border: 2px solid white;
  background: transparent;
  color: white;
  border-radius: 5px;
  font-weight: 800;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
}

.header_details ul {
  justify-content: start !important;
  align-items: start !important;
  display: flex;
  padding-left: 0;
}

input::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

.header_details ul li {
  font-family: 'Outfit', sans-serif;

  color: #fff;
  list-style: none;
  padding: 10px 20px;
  transition: 0.3s all;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.64px;
  margin-left: 3%;
}

/* HERO SECTION */
#hero {
  padding-bottom: 150px;
  position: relative;
}

.hero-side_details {
  margin-top: 9em;
  max-width: 715px;
  margin-left: 35px;
}

.hero-side_details h1 {
  font-family: 'Outfit', sans-serif;
  color: #8D0AE2;
  font-weight: 600;
  font-size: 58px;
}

.hero-side_details p {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 26px;
}

.hero_img {
  max-width: 648px;
  position: absolute;
  right: -21px;
  top: 93px;
  padding-right: 35px;
}

.hero_img::before {
  content: '';
  position: absolute;
  height: 981.44px;
  width: 1003.42px;
  right: -21rem;
  z-index: -1;
  top: -14rem;
  background: url(./images//hero_bg.png);
  background-repeat: no-repeat !important;
}
@media screen and (max-width: 612px) {
  .client_img h1 {
    color: #fff;
    font-size: 46px;
    left: 2rem !important;
    position: absolute;
    right: 0;
    text-align: start;
    top: 3rem !important;
}
}
.hero_img img {
  filter: drop-shadow(30px 10px 4px #4444DD),
    drop-shadow(offset-x offset-y blur-radius color)
}

.try-allow-btn {
  font-family: 'Outfit', sans-serif;
  border: 2px solid #8D0AE2;
  color: #8D0AE2;
  background: transparent;
  border-radius: 5px;
  font-style: normal;
  height: 50px;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 15px 32px;
  margin-right: 12px;
  transition: .4s;
  cursor: pointer;
  outline: none;
}

.try-allow-btn:hover {
  border: 2px solid #8D0AE2;
  outline: none;
  background: #8D0AE2;
  color: #fff;
}

.hero_bg {
  border-radius: 23px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 204px;
  top: -32px;
  background: linear-gradient(88.06deg, rgba(141, 10, 226, 0.76) -45.35%, rgba(255, 255, 255, 0) 46.72%);
  border: 1px solid #FFFFFF;
  filter: drop-shadow(-50px -33px 120px rgba(141, 10, 226, 0.38)), rgba(141, 10, 226, 0.76) rgba(255, 255, 255, 0) rgba(255, 255, 255, 1);
  border-radius: 23px;
  transform: rotate(46.18deg);
  border: 1px solid rgba(255, 255, 255, 1);
  z-index: -1;
}

/* Slider Section */
.swiper {
  width: 100%;
  height: 100vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  color: #fff;
  background: #121212;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

#slider {
  padding-bottom: 150px;
  position: relative;
}

.slider_heading {
  font-family: 'Outfit', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.slider_heading h2 {
  color: #182955;
}

.slider_heading h2 span {
  color: #8D0AE2;
  font-weight: 600;
  font-size: 46px;
}

/* Alloy about section */
#alloy_about {
  padding: 75px 0;
}

.alloy_content {
  max-width: 450px;
  color: #182955;
}

.alloy_content h1 {
  font-family: 'Outfit', sans-serif;
  font-size: 46px;
  font-weight: 700;
  color: #0e2a55;
}

.alloy_content h1 span {
  font-weight: 600;
  font-family: 'Outfit', sans-serif;
  font-size: 46px;
}

.alloy_content p {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: black;
}

.alloy_button {
  cursor: pointer;
  font-family: 'Outfit', sans-serif;
  font-weight: 800;
  padding: 16px 32px;
  background: #8D0AE2;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  display: flex;
  color: #fff;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.alloy_button:focus {
  outline: none;
}

span.MuiSwitch-thumb.css-19gndve {
  color: #8D0AE2 !important;
  width: 24px !important;
  height: 24px !important;
}

.css-1yjjitx-MuiSwitch-track {
  border-radius: 4px !important;
  background-color: rgba(37, 64, 143, 0.2) !important;
  margin-top: 4px;
}

.video {
  position: relative;
  width: 100%;
  height: 430px;
  background: #D9D9D9;
  border-radius: 20px;
}

.alloy_bg {
  background-image: url(./images/hero_bg.png);
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left;
  background-position: left;
  top: -176px;
}

/* Clients */
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgb(109 141 173 / 15%);
  border-radius: 10px;
  padding: 11px 23px;
  margin: 0 auto;
  height: 108px;
}

.crc {
  padding: 0px 0px 75px;
}

.client_heading h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #25408F;
}

#client {
  padding: 75px 0;
}

.client_img h1 {
  position: absolute;
  right: 0;
  top: 12rem;
  left: 45rem ;
  color: #fff;
  font-size: 46px;
  text-align: start;
}

.client_img-21 {
  position: relative;
}

.client_img-21 img {
  height: 100% !important;
}

.banner-img-left-box {
  position: absolute;
  width: 60%;
  height: 100%;
  padding-top: 74px;
  padding-right: 200px;
  top: 0px;
  right: 0px;
  flex-direction: column;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.banner-img-left-box h1 {
  color: white;
  font-weight: 600;
  font-size: 50px !important;
}

.client_img-21 .banner-img-left-box .paly-store-2 {
  height: auto !important;
  margin-right: 115px;
  margin-top: 20px;
}

/* FOOTER */
.quicklink ul li {
  text-align: start;
  font-size: 20px;
  list-style: none;
  font-weight: 700;
}

.quicklink ul li a {
  text-align: start;
  font-size: 20px;
  font-weight: 700;
  color: #000000 !important;
}

.quicklink h2 {
  font-size: 20px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  color: #8D0AE2;
}

.follow h2 {
  font-size: 20px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  color: #8D0AE2;
}

.follow ul li {
  font-family: 'Roboto', sans-serif;
  text-align: start;
  list-style: none;
  font-size: 20px;
  font-weight: 700;
}

.extra ul li {
  font-family: 'Roboto', sans-serif;
  text-align: start;
  list-style: none;
  font-size: 20px;
  font-weight: 700;
}

.extra ul {
  margin-top: 33px;
  left: 38rem;
  font-weight: 700;
}

.email h2 {
  font-size: 20px;
  font-family: 'Outfit', sans-serif;
  color: #8D0AE2;
  font-weight: 700;
}

.email input {
  width: 100%;
  padding: 10px;
  border: 1px solid rgba(24, 41, 85, 0.2);
  border-radius: 5px;
  outline: none;
}

.email input:focus {
  border: 1px solid #8D0AE2 !important;
}

.email input:focus>::placeholder {
  display: none !important;
}

.email button {
  margin-top: 20px;
  width: 100%;
  padding: 12px;
  background: #8D0AE2;
  color: #fff;
  border: none;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 800;
}

.footer_content p {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  font-size: 14px;
  margin-top: 65px;
}

.left {
  display: flex;
}

.left p {
  color: #000000;
  margin-left: 10px;
  font-weight: 400;
}

.right p {
  color: #000000;
  font-weight: 400;
  margin: 0px;
}

.right {
  display: flex;
  align-items: center;
  justify-content: end;
}


.footer_end {
  position: relative;
}

.footer_end_content {
  border-top: 1px solid #000;
  padding-top: 10px;
  padding-bottom: 20px;
}

.footer_end_content p {
  margin-inline: 5px;
}

@media only screen and (max-width:1205px) {
  .banner-img-left-box {
    position: absolute;
    width: 60%;
    height: 100%;
    padding-top: 74px;
    padding-right: 90px;
    top: 0px;
    right: 0px;
    flex-direction: column;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}


/* ******** Mobile Responsive ******** */
@media only screen and (max-width:768px) {
  .land-scape-auth{
    display: none;
  }
  .extra-left-account{
    display: block;
  }
  .extra-left-account{
    padding-left: 0px !important;
  }
  .right_form {
    padding-top: 0px !important; 
    padding-left: 0px !important; 
  }
 
  .blog-detalil-image .client-inner-page-banner{
    width: 100% !important;
  }
  /* ==========Navbar========= */
  .header_details li {
    display: none;
  }

  body .responsive-padding-0 {
    padding: 0px !important;
  }

  body .responsive-padding-0-1 {
    padding: 0px !important;
    padding-top: 20px !important;
  }

  .filtration-input-sec {
    position: relative;
    height: 60px !important;
  }

  .filtration-func-sec {
    height: 60px !important;
    overflow-x: scroll !important;
    margin-inline: 20px;
  }

  .filtration-input-sec input {
    background-color: transparent;
    border: 1px solid #18295533;
    border-radius: 5px;
    padding-block: 10px;
    width: 100%;
    margin-right: 8px;
    margin-left: 8px;
    padding-inline: 20px;
  }

  .filtration-func-sec button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 13px !important;
    text-transform: capitalize !important;
    padding-inline: 10px;
    width: 180px !important;
  }

  .filtration-func-sec button:hover {
    background-color: transparent !important;
    outline: none;
  }

  .filteration-bar {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: fit-content !important;
    background-color: white;
    border-radius: 10px;
  }

  .burger {
    display: block;
    top: 46px;
    right: 40px;
  }

  .header_btn {
    display: none !important;
  }

  #header {
    font-family: var(--out);
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 0 10px 0;
    position: relative;
  }

  .header_btn {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 95px;
    font-weight: 800;
  }
}


/* ======================================================carousel===================================== */
.ant-carousel .slick-prev {
  left: 10px !important;
  background: green !important;
  z-index: 99 !important;
}

.ant-carousel .slick-prev button {
  background-color: transparent !important;
}

.ant-carousel .slick-next button {
  background-color: transparent !important;
}

.ant-carousel .slick-dots li button:hover {
  background-color: transparent !important;
}

.ant-carousel .slick-dots li button:hover {
  background-color: transparent !important;
}

.ant-carousel .slick-next {
  right: 10px !important;
  background: green !important;
  z-index: 99 !important;
}

.left-slide {
  height: 100%;
  padding-left: 120px;
  padding-block: 100px;
}

.right-slide {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-slide-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-slide-21 {
  height: 100%;
  display: none;
  position: absolute;
  background-color: transparent !important;
  right: 0px;
  top: 0px;
  z-index: 3;
  padding-right: 150px;
  align-items: center;
  justify-content: center;
}


.right-slide-22 {
  height: 100%;
  display: none;
  position: absolute;
  background-color: transparent !important;
  right: 0px;
  top: 0px;
  z-index: 3;
  padding-right: 150px;
  align-items: center;
  justify-content: center;
}

.right-slide-23 {
  height: 100%;
  display: none;
  position: absolute;
  background-color: transparent !important;
  right: 0px;
  top: 0px;
  z-index: 3;
  padding-right: 150px;
  align-items: center;
  justify-content: center;
}

.right-slide-21-d {
  height: 100%;
  display: flex;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 3;
  padding-right: 100px;
  align-items: center;
  justify-content: center;
}








.asdasdad img:nth-child(1) {
  position: absolute;
  right: 200px;
  bottom: 0px;
  z-index: 2;
}

.asdasdad img:nth-child(2) {
  position: absolute;
  right: 112px;
  bottom: 263px;
}

.asdasdad img:nth-child(3) {
  position: absolute;
  right: 133px;
  bottom: 170px;
}

.asdasdad img:nth-child(4) {
  position: absolute;
  right: 326px;
  bottom: 147px;
}


.right-slide-21-d img:nth-child(1) {
  position: absolute;
  right: 200px;
  bottom: 0px;
  z-index: 2;
}

.right-slide-21-d img:nth-child(2) {
  position: absolute;
  right: 112px;
  bottom: 263px;
}

.right-slide-21-d img:nth-child(3) {
  position: absolute;
  right: 133px;
  bottom: 170px;
}

.right-slide-21-d img:nth-child(4) {
  position: absolute;
  right: 326px;
  bottom: 147px;
}


















.right-slide-22-d-ee img:nth-child(1) {
  position: absolute;
  right: 70px;
  z-index: 2;
}

.right-slide-22-d-ee img:nth-child(2) {
  position: absolute;
  right: 350px;
}

.right-slide-22-d-ee img:nth-child(3) {
  position: absolute;
  right: 150px;
  bottom: 170px;
}

.right-slide-22-d-ee img:nth-child(4) {
  position: absolute;
  right: 150px;
  bottom: 280px;
}


.right-slide-22-d img:nth-child(1) {
  position: absolute;
  right: 70px;
  z-index: 2;
}

.right-slide-22-d img:nth-child(2) {
  position: absolute;
  right: 350px;
}

.right-slide-22-d img:nth-child(3) {
  position: absolute;
  right: 150px;
  bottom: 170px;
}

.right-slide-22-d img:nth-child(4) {
  position: absolute;
  right: 150px;
  bottom: 280px;
}
















.right-slide-22-d {
  height: 100%;
  background-color: transparent;
  display: flex;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 3;
  padding-right: 100px;
  align-items: center;
  justify-content: center;
}

.right-slide-23-d img:nth-child(1) {
  position: absolute;
  right: 60px;
  z-index: 1;
}

.right-slide-23-d img:nth-child(2) {
  position: absolute;
  right: 400px;
}

.right-slide-23-d img:nth-child(2) {
  position: absolute;
}

.right-slide-23-d {
  height: 100%;
  display: flex;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 3;
  padding-right: 100px;
  align-items: center;
  justify-content: center;
}


.silde-serial-num {
  border: 2px solid#9a00df;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  background-color: #eecdf8;
}

.silde-serial-num h2 {
  color: #9a00df;
  margin: 0px;
  font-weight: 800;

}

.slider-name-sec h1 {
  font-weight: 800;
  color: #11418f;
}

.slider-descrip-sec {
  color: black;
  font-size: 20px;
  font-weight: 400;
}

.ant-carousel .slick-slide img {
  display: inline-flex !important;
}





.price-btn-3 button {
  background-color: #9a00df;
  color: white;
  transition: 1s all;
  border: none;
  font-size: 17px;
  border-radius: 5px;
  padding-block: 10px;
  padding-inline: 30px;
  font-weight: 500;
  position: relative;
  outline: none;
}


.price-btn-3 button .animation-span-1 {
  background-color: #9a00df;
  height: 100%;
  width: 15%;
  position: absolute;
  right: 0px;
  z-index: 99;
  top: 0px;
  border-radius: 5px;

}

.price-btn-3 .btn-animation-arrow {
  margin-left: 10px;
  transition: 0.3s all;
}

.price-btn-3 button:hover .btn-animation-arrow {
  transform: translate(15px, -15px);
}

.price-btn-3 button .animation-span-2 {
  background-color: #9a00df;
  height: 13px;
  z-index: 99;
  width: 100%;
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 5px;
}


.price-btn-3 button:hover .btn-animation-arrow-2 {
  transform: translate(-13px, 0px);
}

.price-btn-3 .btn-animation-arrow-2 {
  transform: translate(-27px, 16px);
  transition: 0.3s all;
}

.next {
  display: none !important;
}



.animate-next-img {
  animation: hasd 0.4s ease-in-out;
}

.animate-next-img1 {
  animation: www 0.8s ease-in-out;
}

.animate-next-img1-anima-1 {
  animation: www 1.4s ease-in-out;
}

.animate-next-img1-anima-2 {
  animation: www 1.6s ease-in-out;
}

.animate-next-img-back {
  opacity: 0;
}

@keyframes hasd {
  from {
    opacity: 0;
    transform: translate(300px, -100px);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes www {
  from {
    opacity: 0;
    transform: translate(300px, 100px);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}



.ant-carousel .slick-prev {
  height: 60px !important;
  width: 60px !important;
  border: 2px solid #1e3760 !important;
  background-color: #d1d9e9 !important;
  border-radius: 50% !important;
}

.ant-carousel .slick-next {
  height: 60px !important;
  width: 60px !important;
  border: 2px solid #1e3760 !important;
  background-color: #d1d9e9 !important;
  border-radius: 50% !important;
  position: relative;
}

.ant-carousel .slick-next::after {
  font-family: FontAwesome;
  position: absolute;
  content: "\f178 ";
  top: 28px;
  left: 18px;
  color: #1e3760;
  z-index: 22;
  font-size: 23px !important;
}

.ant-carousel .slick-prev::after {
  font-family: FontAwesome;
  content: "\f177";
  position: absolute;
  top: 28px;
  left: 18px;
  color: #1e3760;
  z-index: 22;
  font-size: 23px !important;

}


.slick-dots-bottom {
  z-index: 999;
}

.ant-carousel .slick-dots li.slick-active {
  width: 74px !important;
  height: 6px !important;
  background-color: #1e3760 !important;
}

.ant-carousel .slick-dots li.slick-active button {
  display: none !important;
  background-color: #d1d9e9 !important;
}

.ant-carousel .slick-dots li button {
  display: none;
}

.ant-carousel .slick-dots li {
  position: relative;
  background-color: #d1d9e9;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 36px !important;
  height: 6px !important;
  margin: 0 2px;
  margin-right: 3px;
  border-radius: 10px !important;
  margin-left: 3px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}

.ant-collapse-item {
  margin-top: 28px !important;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px !important;
  border-radius: 20px;
}

.ant-collapse>.ant-collapse-item:last-child,
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  border-radius: 20px !important;
}

.responsive-slider {
  display: none;
}

.desk-slider {
  position: relative;
}

.herobg-left {
  position: absolute;
  right: 0px;
}


@media screen and (max-width: 1000px) {
  .responsive-slider {
    display: block;
  }

  .desk-slider {
    display: none;
  }

}

@media screen and (max-width: 700px) {
  .form_box {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgb(109 141 173 / 15%);
    border-radius: 20px;
    height: auto;
    margin-right: 0px !important;
    padding: 60px;
    margin-top: 112px;
  }
  .footer-third-section{
    padding-right: 0px;
    padding-left: 1rem !important;
  }
  
  .footer-forth-section{
    padding-left: 1rem !important;
  }
  .contact_left-side{
    padding-top: 121px; 
    padding-left: 0px !important;
  }
  .contact_left-side {
    width:100% !important;
  }
  .hero_img {
    max-width: 648px;
    position: relative;
    right: auto;
    top: auto;
    padding-right: 35px;
  }

  .about_mid-right {
    padding-left: 0px !important;
  }

  .media_button {
    width: 361px !important;
    height: 50px !important;
    margin-left: 23% !important;
  }

  .hero_img::before {
    content: '';
    position: absolute;
    height: 981.44px;
    width: 100%;
    right: -1rem;
    z-index: -1;
    top: -64rem;
    background: url(./images//hero_bg.png);
    background-repeat: repeat;
    background-repeat: no-repeat !important;
  }

  .hero_img img {
    width: 100%;
    filter: drop-shadow(30px 10px 4px #4444DD),
      drop-shadow(offset-x offset-y blur-radius color)
  }

  .client_img img {
    width: 100% !important;
  }

  .video::before {
    content: '';
    position: absolute;
    height: 100vh;
    width: 100%;
    right: -22rem;
    z-index: -1;
    top: -6rem;
    background: url(./images/Hero-BG.png);
    background-repeat: no-repeat;
    display: none !important;
  }

  .video {
    position: relative;
    width: 100%;
    height: 230px;
    background: #D9D9D9;
    border-radius: 20px;
  }

  .hero-side_details h1 {
    font-family: 'Outfit', sans-serif;
    color: #8D0AE2;
    padding-block: 40px !important;
    font-weight: 600;
    font-size: 48px;
  }

  .hero-side_details {
    margin-top: 0em;
    max-width: 100%;
    margin-left: 0px;
  }

  .header-btns-two {
    display: flex;
    flex-direction: column;
  }

  .price-btn button {
    background-color: #9a00df;
    color: white;
    transition: 1s all;
    width: 100%;
    border: none;
    margin-bottom: 30px;
    cursor: pointer;
    border-radius: 5px;
    padding-block: 15px;
    padding-inline: 60px;
    position: relative;
    outline: none;
    font-weight: 800;
  }

  .try-allow-btn {
    font-family: 'Outfit', sans-serif;
    border: 2px solid #8D0AE2;
    color: #8D0AE2;
    background: transparent;
    border-radius: 5px;
    font-style: normal;
    height: 50px;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 15px 32px;
    margin-right: 0px;
    margin-bottom: 10px;
    transition: .4s;
    cursor: pointer;
    outline: none;
  }

  #hero {
    padding-bottom: 100px;
  }

  .left-slide {
    padding-left: 0px !important;
  }

  .right-slide-2 {
    height: auto;
  }

  .right-slide-2 img {
    height: 100%;
    width: 100%;
  }

  .ant-carousel .slick-prev {
    left: 10px !important;
    background: green !important;
    z-index: 99 !important;
    display: none !important;
  }

  .ant-carousel .slick-next {
    right: 10px !important;
    background: green !important;
    z-index: 99 !important;
    display: none !important;
  }

  .ant-carousel .slick-dots-bottom {
    bottom: -80px;
  }

  .price-add .price-chip h1 {
    font-size: 30px;
  }

  .price-add .price-chip del {
    font-size: 15px;
  }

  .price-add h5 {
    font-size: 18px;
  }

  .price-card .product-price-detail p {
    font-size: 12px;
  }

  .price-btn button {
    background-color: #9a00df;
    color: white;
    transition: 1s all;
    width: 50%;
    border: none;
    margin-bottom: 30px;
    cursor: pointer;
    border-radius: 5px;
    padding-block: 15px;
    padding-inline: 30px;
    position: relative;
    outline: none;
    font-weight: 800;
    width: 100% !important;
  }

  .alloy_content h1 {
    font-family: 'Outfit', sans-serif;
    font-size: 36px;
  }

  .alloy_content h1 span {
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    font-size: 36px;
  }

  .alloy_content {
    text-align: center;
  }

  .alloy_content div {
    display: flex;
    align-items: center;
    justify-content: center !important;
    width: 100%;
  }

  .alloy_content button {
    width: 100% !important;
  }

  .play_btn {
    position: absolute;
    top: 42% !important;
    left: 48% !important;
  }

  #client {
    padding: 0px 0px;
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgb(109 141 173 / 15%);
    border-radius: 10px;
    padding: 11px 23px;
    margin: 0 auto;
    width: 100%;
    margin-top: 10px;
    height: 108px;
  }

  .comment-of-client i h6 {
    color: black;
    font-size: 17px !important;
    letter-spacing: 1px;
  }

  .ant-collapse-header .ant-collapse-header-text {
    font-size: 18px;
  }

  .quicklink ul li {
    font-size: 14px;
  }

  .follow ul li {
    font-size: 14px;
    padding: 5px 0px;
  }

  .extra ul li {
    font-size: 14px;
    margin-left: 40px;
  }

  .email {
    position: static !important;
  }

  .right {
    position: static !important;
  }

  .quicklink ul {
    padding-left: 0px !important;
  }

  .follow ul {
    padding-left: 0px !important;
  }

  .extra ul {
    padding-left: 0px !important;
  }

  .extra li {
    margin-left: 0px !important;
  }

  .email input {
    width: 100%;
  }

  .ant-carousel .slick-dots-bottom {
    bottom: -40px !important;
  }

  .client_img h1 {
    position: absolute;
    left: 2rem ;
    top: 5rem;
    color: #fff;
    font-size: 36px;
    text-align: start;
  }

  .client_img .paly-store {
    position: absolute;
    top: 13rem !important;
    left: 2rem !important;
    width: fit-content !important;
  }

  .extra ul {
    margin-top: 0px !important;
  }

  .extra ul li {
    font-size: 14px;
    padding: 5px 0px;
  }
}

#footer h2 {
  text-transform: uppercase !important;
}

button {
  text-transform: uppercase !important;
  letter-spacing: 1px;
}

button:hover {
  background-color: #7820b3;
  color: white;
}

.paly-store {
  position: absolute;
  top: 21rem;
  left: 45rem;
}








#header {
  font-family: var(--out);
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0 10px 0;
  position: relative;
}

.burger {
  cursor: pointer !important;
  border: none !important;
  outline: none !important;
  background: transparent;
  background-color: transparent !important;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: none;
}

.burger:hover {
  background-color: transparent !important;
}

.header.active .burger {
  display: block !important;
}

.burger:before {
  content: '';
  position: absolute;
  width: 30px;
  height: 2px;
  border-radius: 20px;
  background: #ffff;
  top: 33px;
  left: 0;
  transform: translateY(-15px);
  transition: .5s;
}

.burger:after {
  content: '';
  border-radius: 20px;
  position: absolute;
  width: 30px;
  height: 2px;
  box-shadow: 0px -7px #fff;
  background: #ffff;
  top: 33px;
  left: 0;
  transition: .5s;
}

.burger {
  display: block;
  top: 16px !important;
  z-index: 99999 !important;
  right: 20px !important;
  height: 40px;
  width: 40px;
}

.overlay_menu ul {
  padding-left: 0px !important;
}

.nav-links-bar {
  z-index: 999;
  cursor: pointer !important;
  color: white;
}

.nav-links-bar:hover {
  color: #8D0AE2 !important;
}

.header.active .burger::before {
  transform: rotate(45deg);
}

.header.active .burger::after {
  transform: rotate(-45deg);
  box-shadow: none;
}

.header.active .burger {
  position: fixed;
  top: 40px;
}

.overlay_menu {
  width: 0%;
  height: 0%;
  transition: .5s;
}

.overlay_btn {
  width: 115px;
  margin-bottom: 20px;
  height: 52px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  background: transparent;
  color: white;
  border-radius: 5px;
  text-transform: uppercase;
  margin-top: 0;
  font-weight: 800;
  font-size: 14px;
  transition: .4s;
  cursor: pointer;
}

.overlay_btn2 {
  margin-top: 4px;
  padding: 12px 20px;
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
  border-radius: 5px;
  font-weight: 800;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
}

.overlay_menu ul li a {
  position: relative;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
}

.overlay_menu ul li a::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 2px;
  background: #fff;
  left: 0;
  bottom: -5px;
  transition: .5s;
}

.overlay_menu ul li a:hover::after {
  width: 100%;
}

.header.active .overlay_menu {
  z-index: 7;
  position: fixed;
  transition: .5s;
  background: radial-gradient(48.09% 112.23% at 93.56% 106.97%, #500680 0%, #8D0AE2 100%);
  height: fit-content !important;
  width: 100% !important;
  top: 0;
  right: 0;
  overflow-x: scroll;
}

.header.active .mobile_navbar {
  display: block !important;
}

.overlay_menu ul {
  transition: .5s;
  display: none;
}

.header.active .overlay_menu ul {
  transition: .5s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 41px;
  text-align: center;
}

.header.active .overlay_menu li {
  font-size: 24px;
  color: #fff !important;
  list-style: none;
  padding: 44px 0px 7px;
}

/* =================================================================Account Profile======================================================== */

.side_menu {
  background-image: url(./images/Mask\ group\ 1.png);
  position: relative;
  background-repeat: no-repeat;
  width: 294px;
  height: 159px;
  backdrop-filter: blur(4px);
  background: radial-gradient(48.09% 112.23% at 93.56% 106.97%, #500680 0%, #8D0AE2 100%);
}

.mask_bg {
  background-image: url(./images/Mask\ group\ 1.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.side_menu-heading {
  text-align: start;
  padding-top: 71px;
  padding-left: 21px;
}

.side_menu-heading sub {
  font-size: 20px;
  color: #1EC130;
  font-weight: 700;
  text-transform: uppercase;
}

.side_menu-heading h3 {
  font-weight: 400;
  color: #fff;
  font-size: 20px;
  padding-top: 8px;
}

.side_content {
  background: #F7F5F2;
  width: 294px;
  height: 440px;
}

.side_content h2 {
  padding-top: 31px;
  font-size: 16px;
  color: #8D0AE2;
  text-transform: uppercase;
  font-weight: 700;
}

.side_content ul {
  padding-left: 0px;
}

.side_content {
  padding-left: 22px;
}

.side_content ul li {
  list-style: none;
  padding-top: 36px;
}

.side_content ul li a {
  text-transform: uppercase;
  color: #182955;
  font-size: 16px;
  font-weight: 700;
  padding-left: 21px;
  font-family: 'Outfit', sans-serif;

}

.profile_heading {
  padding-top: 58px;
}

.profile_heading h3 {
  font-size: 36px;
  font-weight: 700;
  color: #182955;
  font-family: 'Outfit', sans-serif;
}

.profile_heading span {
  font-family: 'Roboto', sans-serif;
  color: #000000;
  font-weight: 400;
  font-size: 20px;

}

.account_form {
  padding-top: 27px;
}

.account_form h1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  color: #25408F;
}

.css-1diafny-MuiSlider-root {
  border-radius: 12px;
  box-sizing: content-box;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  color: #9c27b0;
  -webkit-tap-highlight-color: transparent;
  height: 4px;
  width: 24px !important;
  padding: 13px 0;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
  color: #8D0AE2 !important;
}

.css-1yjjitx-MuiSwitch-track {
  width: 24px !important;
  height: 4px !important;
  /* border: 4px solid rgba(37, 64, 143, 0.2) !important; */
}

.css-jsexje-MuiSwitch-thumb {
  height: 18px !important;
  width: 18px !important;
}

.form-control {
  width: 361px;
  height: 40px;
  background: #FFFFFF !important;
  border: 1px solid rgba(24, 41, 85, 0.2) !important;
  border-radius: 5px !important;
  padding: 8px 16px !important;
}

.form-control:focus {
  border: 1px solid #8D0AE2 !important;
  box-shadow: none !important;
}

input:focus::placeholder {
  transition: .2s;
}

input:focus::placeholder {
  color: transparent !important;
}

.right_form {
  padding-top: 174px;
}

.right_form h1 {
  text-transform: uppercase;
  font-size: 20px;
  color: #25408F;
  font-weight: 700;
}

/* =================================================================Account Subscription======================================================== */
.sub_box-1 h1 {
  font-family: 'Roboto', sans-serif;
  color: #000000;
  font-weight: 700;
  font-size: 20px;
}

.sub_box-2 h1 {
  font-family: 'Roboto', sans-serif;
  color: #000000;
  font-weight: 700;
  font-size: 20px;
}

.sub_box-1 span {
  color: rgba(37, 64, 143, 0.5);
  font-weight: 400;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;

}

.sub_box-2 span {
  color: rgba(37, 64, 143, 0.5);
  font-size: 16px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;

}

.sub_box-1 {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  width: 407px;
  height: 130px;
  box-shadow: 0px 8px 16px rgba(109, 141, 173, 0.15);
  border-radius: 10px;
  margin-top: 34px;
}

.sub_box-2 {
  margin-top: 24px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  width: 407px;
  height: 130px;
  box-shadow: 0px 8px 16px rgba(109, 141, 173, 0.15);
  border-radius: 10px;
  margin-top: 24px;
}

.d {
  display: flex;
  padding: 10px 0px;
  align-items: center;
}

.d span {
  font-family: 'Roboto', sans-serif;
  /* color: #25408F !important; */
  font-weight: 400;
}

/* =================================================================About Section======================================================== */
.about_upper-content {
  height: auto;
}

.about_upper-content h1 {
  color: #8D0AE2;
  font-family: 'Outfit', sans-serif;
  font-size: 58px;
  font-weight: 600;
}

.about_upper-content h6 {
  font-size: 24px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}

.about_upper-content {
  padding-top: 71px;
  padding-left: 89px;
}

.about_mid-left {
  padding-top: 91px;
  padding-left: 89px;
  width: 612px;
}

.about_mid-left h1 {
  color: #182955;
  font-weight: 600;
  font-family: 'Outfit', sans-serif;

}

.about_mid-left h4 {
  font-size: 28px;
  color: #25408F;
  font-weight: 600;
  font-family: 'Outfit', sans-serif;
}

.about_mid-left p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

.about_mid-right {
  padding-top: 65px;
  padding-left: 0px !important;
}

/* .width {

  width: 644.39px !important;
  height: 400.32px !important;
} */

#about_upper {
  position: relative;
}

.about_bg-upper {
  position: absolute;
  background: url(./images/hero_bg.png);
  /* height: 100%;
  
  width: 100%; */
  height: 981.44px;
  width: 1003.42px;
  right: 0;
  background-position: right;
  background-repeat: no-repeat;
  background-position-y: inherit;
}

.alloy_info-heading {
  width: 536px;
  height: 281px;
  padding-top: 148px;
}

.alloy_info-heading h1 {
  font-weight: 600;
  font-size: 46px;
  color: #182955;
  font-family: 'Outfit', sans-serif;

}

#boxes h6 {
  font-family: 'Roboto', sans-serif;

}

.alloy_info-heading p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 20px;
}

.about_box {
  margin-top: 29px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgb(109 141 173 / 15%);
  border-radius: 10px;
  text-align: center;
  padding: 16px 24px 16px 24px;
  width: 100%;
  height: 271px;
}

.about_box p {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
}

#alloy_back h1 {
  color: '#182955';
  font-weight: 600;
}

#alloy_back h2 {
  font-family: 'Outfit', sans-serif;
  font-size: 46px;
  color: #182955;
  font-weight: 600;
}

#alloy_back {
  padding-top: 55px;
  text-align: center;
}

#alloy_back h6 {
  font-weight: 600;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  color: #000000;
}

#alloy_back h3 {
  font-weight: 600;
  font-size: 28px;
  color: #25408F;
  padding-top: 20px;
  font-family: 'Roboto', sans-serif;

}

#alloy_back h4 {
  font-size: 28px;
  font-family: 'Roboto', sans-serif;
  padding-top: 20px;
}

/* =================================================================Contact Section======================================================== */

.contact_left-side {
  padding-top: 121px;
  padding-left: 50px;
}

.contact_left-side h1 {
  font-weight: 600;
  font-size: 58px;
  color: #8D0AE2;
  font-family: 'Outfit', sans-serif;

}

.contact_left-side p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
}

.contact_left-side h4 {
  color: #25408F;
  font-weight: 700;
  font-size: 20px;
  font-family: 'Outfit', sans-serif;
  text-transform: uppercase;

}

.contact_left-side h5 span {
  font-family: 'Roboto', sans-serif;
  color: rgb(30, 193, 48);
  font-weight: 700;
  font-size: 20px;
}

textarea.form-control {
  /* width: 400px; */
  width: 100%;
  height: 149px;
}

.form_box {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgb(109 141 173 / 15%);
  border-radius: 20px;
  height: auto;
  margin-right: 100px !important;
  padding: 60px;
  margin-top: 112px;
}

.form_box h3 {
  color: #182955;
  font-weight: 700;
  font-size: 36px;
}

.form_bg {
  background: url(./images/hero_bg.png);
  width: 1003.42px;
  height: 981.44px;
  right: 0 !important;
  position: absolute;
  top: 0 !important;
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: center;
}

.form_box input {
  width: 100% !important;
  transition: .2s;
}

.margin {
  margin-left: 0px !important;
}

textarea.form-control:hover {
  border: 1px solid #121212 !important;
}

@media only screen and (max-width:1005px) {

  /* ==========Navbar========= */
  .header_details li {
    display: none;
  }

  .burger {
    display: block;
    top: 46px;
    z-index: 99999 !important;
    right: 40px;
  }

  .header_btn {
    display: none !important;
  }

  #header {
    font-family: var(--out);
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 0 10px 0;
    position: relative;
  }

  .header_btn {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 95px;
    font-weight: 800;
  }

  .cutom-margin-top {
    margin-top: 20px !important;
  }

  .pricing_heading h2 {
    font-size: 35px;
  }

  .alloy_bg {
    background-image: url(./images/hero_bg.png);
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: left;
    background-position: left;
    top: -176px;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}

.banner-img-left-box h1 {
  font-size: 48px !important;
}

@media only screen and (max-width:500px) {
  .side_content {
    width: 100% !important;
  }
  .right_form{
    padding-top: 50px !important;
  }
  .about_mid-left p {
    width: 100% !important;
  }
  .side_content ul li a {
    padding-left: 4px;
  }

  .about_mid-right {
    padding-left: 0px !important;
  }

  .about_bg-upper {
    top: 0px !important;
  }

  .img_media {
    padding-left: 0px !important;
  }

  .side_menu {
    width: 100% !important;
  }

  .side_menu-heading {
    text-align: center !important;
  }

  .side_content ul {
    text-align: center !important;
  }

  .profile_heading {
    text-align: center !important;
  }

  .sub_box-1 {
    width: 100% !important;
    justify-content: center !important;
  }

  .sub_box-2 {
    width: 100% !important;
    justify-content: center !important;
  }

  .d {
    justify-content: center !important;
  }

  .inputs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .account_form {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    text-align: center;
    align-items: center;
  }

  .right_form {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    text-align: center;
    align-items: center;
  }

  .media_button {
    width: 361px !important;
    height: 50px !important;
    margin-left: 0px !important;
    margin-top: 25px !important;
  }

  .account_form h1 {
    text-align: center !important;
    font-size: 20px;
    text-transform: uppercase;
    color: #25408F;
  }

  .right_input {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0px !important;
  }
  .right_form h1 {
    text-align: center;
  }

  .about_upper-content {
    text-align: center;
    width: 100%;
    height: 100%;
    padding-left: 0px !important;
  }

  .about_mid-left {
    padding-top: 91px;
    width: 100%;
    text-align: center;
    padding-left: 0px;
  }

  .width {
    width: 100% !important;
    height: 230px !important;
  }

  .alloy_info-heading {
    width: 100%;
    height: 100%;
    padding-top: 148px;
    text-align: center;
  }

  .about_box {
    margin-top: 29px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgb(109 141 173 / 15%);
    border-radius: 10px;
    margin-left: 5px;
    text-align: center;
    width: 100%;
  }

  .form_box {
    width: 100%;
  }

  .form_bg {
    /* top: 21rem; */
    bottom: 0;
  }

  .contact_heading img {
    height: 200px;
  }
}

@media only screen and (max-width:1024px) {
  .about_bg-upper {
    bottom: 0px;
  }

  .img_media {
    padding-left: 0px !important;
  }
}

#main_blog_block {
  background-image: url(./images/blogBg.png);
  background-size: auto;
  background-repeat: no-repeat;
}

#main_blog_block-2 {
  background-image: url(./images/hero_bg.png);
  background-size: auto;
  background-position: right;
  background-position-y: -10%;
  background-repeat: no-repeat;
}

.news-lettet-image-box {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
}


.news-lettet-image-box-right h2 {
  color: #25408F;

  font-family: 'Outfit', sans-serif !important;
  font-weight: 700;
  font-size: 46px;
}

.news-lettet-image-box-right-tag span {
  border: 1px solid #8D0AE2;
  border-radius: 5px;
  background-color: transparent;
  color: #8D0AE2;
  padding: 4px;
}

.news-lettet-image-box-right-para p {
  font-weight: 500;

  font-family: 'Outfit', sans-serif !important;
  font-size: 15px;
}

.news-lettet-image-box-right-date-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filteration-bar {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 60px;
  background-color: white;
  border-radius: 10px;
}

.filteration-bar a {
  color: black
}

.filteration-bar button:hover {
  color: black
}

.filtration-func-sec button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  text-transform: capitalize !important;
  padding-inline: 20px;
}

.filtration-func-sec button:hover {
  background-color: transparent !important;
  outline: none;
}

.filtration-input-sec {
  position: relative;
}

.filtration-input-sec input {
  background-color: transparent;
  border: 1px solid #18295533;
  border-radius: 5px;
  padding-block: 10px;
  width: 100%;
  margin-right: 8px;
  padding-inline: 20px;
}

.filtration-input-sec img {
  position: absolute;
  right: 40px;
}

.main-blog-card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
}

.main-blog-card img {
  border-radius: 20px;
  height: 200px;
  width: 100%;
}

.news-lettet-image-box-right-tag-2 span {
  border: 1px solid #1EC130;
  border-radius: 5px;
  font-weight: 500;
  background-color: transparent;
  color: #1EC130;
  padding: 4px;
}

.news-lettet-image-box-right-2 {
  height: 160px;
}

.news-lettet-image-box-right-2 h5 {
  color: black;
  font-weight: 700;
  font-size: 28px;
  transition: 0.3s all;
}

.news-lettet-image-box-right-2 h5:hover {
  color: #9a00df !important;
}


.price-btn-outinle-2 button {
  background-color: white;
  border: 2px solid #25408F;
  color: #25408F;
  transition: 1s all;
  font-size: 14px;
  font-weight: 800;
  border-radius: 5px;
  font-weight: 800;
  position: relative;
  outline: none;
  padding: 12px 30px;
}

.price-btn-outinle-2 button:hover {
  background-color: transparent !important;
  color: #25408F;
}

.price-btn-outinle-2 button .animation-span-1 {
  background-color: white;
  height: 100%;
  width: 9%;
  position: absolute;
  right: 0px;
  z-index: 99;
  top: 0px;
  border-radius: 5px;
}

.price-btn-outinle-2 .btn-animation-arrow {
  margin-left: 10px;
  transition: 0.3s all;
}

.price-btn-outinle-2 button:hover .btn-animation-arrow {
  transform: translate(15px, -15px);
  opacity: 0;
}

.price-btn-outinle-2 button .animation-span-2 {
  background-color: white;
  height: 17px;
  z-index: 99;
  width: 100%;
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 5px;
}


.price-btn-outinle-2 button:hover .btn-animation-arrow-2 {
  transform: translate(-13px, 0px);
  opacity: 1;
}

.price-btn-outinle-2 .btn-animation-arrow-2 {
  transform: translate(-27px, 16px);
  transition: 0.3s all;
  opacity: 0;
}

/* blog detail */
.blog-detalil-image img {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.blog-detalil-image h3 {
  margin-top: 20px;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
}


.subAuther h6 {
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  margin: 0px;

}

.subAuther p {
  font-family: 'Outfit', sans-serif;
  margin: 0px;
}

.html-parser-data p {
  font-family: 'Outfit', sans-serif;
}

.html-parser-data h1 {
  font-family: 'Outfit', sans-serif;
}

.html-parser-data h2 {
  font-family: 'Outfit', sans-serif;
}

.html-parser-data h3 {
  font-family: 'Outfit', sans-serif;
}

.html-parser-data h4 {
  font-family: 'Outfit', sans-serif;
}

.html-parser-data h5 {
  font-family: 'Outfit', sans-serif;
}

.html-parser-data h6 {
  font-family: 'Outfit', sans-serif;
}

.html-parser-data span {
  font-family: 'Outfit', sans-serif;
}


.html-parser-data img {
  width: 100%;
  height: 100%;
}

.navigator-detail span{
  margin: 0px;
  margin-inline: 20px;
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
  color: #a8b7d4;
}
.html-parser-data li{
  font-size: 17px;
  font-family: 'Outfit', sans-serif;
}

.html-parser-data p{
  font-size: 17px;
  font-family: 'Outfit', sans-serif;
}
.html-parser-data span{
  font-size: 17px;
  font-family: 'Outfit', sans-serif;
}

.navigator-detail p{
  margin: 0px;
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
  color: #a8b7d4;
}
.navigator-detail .active-in-navigator{
    color:#11418f
}

.inner-detail-title h3{
  font-size: 38px;
  font-weight: 700;
  font-family: 'Outfit', sans-serif;
  color:#11418f;
}

.client-inner-page-banner{
  width: 75% !important;
}
.footer-third-section{
  padding-right: 3rem;
}

@media screen and (max-width: 1151px) {
  .filtration-func-sec button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    text-transform: capitalize !important;
    padding-inline: 20px;
  }
}



















@media screen and (max-width: 1258px) {
  .filtration-func-sec button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 13px;
    text-transform: capitalize !important;
    padding-inline: 20px;
  }
}
.login_register_modal{
  background-color: white;
  padding: 10px;
  width: 400px;
}
.modal_login_converter{
  padding-inline: 20px;
}
.modal_login_converter .converter_main{
  width: 100%;
  border: 1px solid #f8f5f2;
  display: flex;
  align-items: center;
  border-radius: 50px;
  position: relative;
}
.modal_login_converter .converter_main .Login_sight{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 12px;
  font-family: 'Outfit', sans-serif !important;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}
.absolute_sight-1{
  position: absolute;
  width: 50%;
  background-color: #9a00df;
  padding-block: 10px;
  border-radius: 50px;
  left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  color: white;
  transform: translate(98%);
  transition: 0.2s all;
  cursor: pointer;

}
.absolute_sight{
  position: absolute;
  width: 50%;
  background-color: #9a00df;
  padding-block: 10px;
  border-radius: 50px;
  left: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  transition: 0.2s all;
  color: white;
  cursor: pointer;

}
.modal_login_converter .converter_main .register_sight{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 12px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}
.img-key{
  position: relative;
  width: 100%;
}
.img-key img{
  position: absolute;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
}
.img-mail img{
  position: absolute;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
}
.img-mail{
  position: relative;
  width: 100%;
}
.modal_login_input .email_login label{
  font-weight: 700;
  font-family: 'Outfit', sans-serif;
  color:black;
}
.modal_login_input .password_login label{
  font-weight: 700;
  font-family: 'Outfit', sans-serif;
  color:black;
}
.modal_login_input .email_login input{
    padding-block: 8px;
    padding-inline: 10px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid rgba(24, 41, 85, 0.2);
    outline: none;
}
.modal_login_input .password_login input{
  padding-block: 8px;
  padding-inline: 10px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(24, 41, 85, 0.2);
  outline: none;
}
.forget_password{
  padding-block: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.forget_password p{
  font-weight:600;
  color: #1EC130;
  font-size: 17px;
}

.Login_with_google{
  width: 100%;
}
.Login_with_google button img{
  margin-right: 20px;
}
.Login_with_google button{
  border: 2px solid #25408F;
  color: #25408F;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  padding-block: 10px;
  font-weight: 700;
  font-family: 'Outfit', sans-serif;
  outline: none;
}
.modal-content{
  border-radius: 10px;
}
.modal-open .modal{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}




































@media screen and (max-width: 1000px) {
  .right_form {
    padding-top: 20px;
    padding-left: 20px;
  }
  .form_box {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgb(109 141 173 / 15%);
    border-radius: 20px;
    height: auto;
    margin-right: 0px !important;
    padding: 60px;
    margin-top: 112px;
  }
  .hero_img {
    max-width: 100%;
    position: relative ;
    right: 0px;
    top: 0px;
    padding-right: 0px;
    display: flex;
align-items: center;
justify-content: center;
  }
  .hero_img::before{
    content: '';
    position: absolute;
    height: 981.44px;
    width: 1003.42px;
    right: -24rem;
    z-index: -1;
    top: -50rem;
    background: url(./images/hero_bg.png);
      background-repeat: repeat;
    background-repeat: no-repeat !important;
  }
  .ant-carousel .slick-dots {
    position: relative !important;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex !important;
    justify-content: center;
    margin-right: 15%;
    margin-left: 15%;
    padding-left: 0;
    list-style: none;
    margin-top: 30px;
  }
  .about_mid-left {
    padding-top: 0px;
    padding-left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .about_mid-left p{
    width: 100%;
  }
  .about_mid-right {
    display: flex;
    align-items: center;
    padding-top: 0px;
    padding-left: 0px !important;
  }
  .video {
    position: relative;
    width: 100%;
    height: 330px;
    background: #D9D9D9;
    border-radius: 20px;
  }
  .alloy_info-heading {
    width: 100%;
    height:auto;
    padding-top: 0px;
  }
  .filteration-bar {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    height: auto;
    background-color: white;
    border-radius: 10px;
  }
  .filtration-func-sec button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    text-transform: capitalize !important;
    padding-inline: 20px;
    padding-block: 10px;
  }
  .filtration-input-sec input{
    margin-right: 10px !important;
    margin: 10px;
  }
  .client_img h1 {
    position: absolute;
    right: 0;
    top: 12rem;
    left: 16rem;
    color: #fff;
    font-size: 46px;
    text-align: start;
  }
  .side_bar_top_bottom {
    position: relative;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
    margin-top: 100px;
  }
  .header.active .overlay_menu {
    z-index: 7;
    position: fixed;
    transition: .5s;
    background: radial-gradient(48.09% 112.23% at 93.56% 106.97%, #500680 0%, #8D0AE2 100%);
    height: 100vh !important;
    width: 100% !important;
    top: 0;
    right: 0;
    overflow-x: scroll !important;
  }
  .dashboard-subscirebed{
    margin-top: 0px !important;
  }
  .footer-third-section{
    padding-right: 0px;
    padding-left: 3rem;
  }
  
  .footer-forth-section{
    padding-left: 3rem;
  }
  .email input{
    width: 100% !important;
  }
  .extra-left-account{
    padding-left: 320px;
  }
  .land-scape-auth{
    display: block;
  }
  .extra-left-account{
    display: none;
  }
}

