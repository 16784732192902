@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root{
    --bx-shado: 0px 8px 16px 0px #6D8DAD26;
}
*{
    margin: 0px;
    padding: 0px;
}
.Platform-base{
    border-radius: 10px;
    box-shadow: var(--bx-shado);
}
.Platform-base .platform-build-sec{
    border: 2px solid #f8f5f2;
    border-radius: 10px;
    margin-top: 60px;
    border-top-left-radius: 0px !important;
}
.Platform-base .platform-build-sec-filter{
    border: 2px solid #f8f5f2;
    border-radius: 10px;
    border-top-left-radius: 10px !important;
}
.build-top-head{
    position: absolute;
    height: 62px;
    width: 130px;
    top: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 2px solid #f8f5f2;
    border-bottom: none !important;
    background-color:white;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.build-top-head h5{
    font-style: normal;
    margin: 0px ;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    font-family: 'Outfit', sans-serif;
    color: #182955 !important;
}
.platform-build-sec p{
    font-family: 'Outfit', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}

.platform-build-sec button{
    font-size: 14px !important;
    margin-block: 10px;
}





.platform-build-sec-filter p{
    font-family: 'Outfit', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}


.bet-sec .build-top-head{
    position: absolute;
    height: 62px;
    width: 130px;
    top: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: none !important;
    border-bottom: none !important;
    background:#f8f5f2;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.bet-sec .build-top-head h5{
    font-style: normal;
    margin: 0px ;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    font-family: 'Outfit', sans-serif;
    color: #182955 !important;
}

.bet-sec .platform-build-sec{
    border: none !important;
    border-radius: 10px;
    background:#f8f5f2;
    margin-top: 60px;
    border-top-left-radius: 0px !important;
}



.bet-sec .build-top-head-right{
    position: absolute;
    height: 62px;
    top: -60px;
    right: 0px;
    display: flex;
    border-radius: 10px;
    border: none !important;
    border-bottom: none !important;
    background:transparent;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.print-pdf{
    height: 100%;
    width: 60px;
    background-color: #8D0AE2 !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.share-link{
    height: 100%;
    width: 60px;
    background: rgba(37, 64, 143, 0.2);
    border: 1px solid #8D0AE2 !important;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.bet-converter{
    background-color: white;
    width: 100%;
    height: 45px;
    padding: 4px;
    border-radius: 50px;
    display: flex;
    position: relative;
}

.left-bet-converter{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.right-bet-converter{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.right-bet-converter h6{
    margin: 0px;
}

.left-bet-converter h6{
    margin: 0px;
}
.left-bet-converter-absolute h6{
    margin: 0px !important;
    color:#25408F;
    font-weight: 600;
}
.left-bet-converter-absolute-op h6{
    margin: 0px !important;
    color:#25408F;
    font-weight: 600;
}
.left-bet-converter-absolute-op{
    position: absolute;
    left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    background:#d1d9e9;
    border-radius: 50px;
    padding-block: 9px;
    transform: translateX(98%);
    transition: 0.2s all;
}

.left-bet-converter-absolute{
    position: absolute;
    left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    background:#d1d9e9;
    border-radius: 50px;
    padding-block: 9px;
    transform: translateX(0%);
    transition: 0.2s all;
}
.trading-meter-sec{
    display: flex;
    align-items: center;
}
.trading-meter-sec .trademeter{
    display: flex;
    align-items: center;
    flex-direction:column;
    width: 20%;
    padding-block: 15px;
    border-bottom: 1px solid #d7d7d7;
}
.trading-meter-sec .trademeter-1{
    display: flex;
    align-items: center;
    flex-direction:column;
    width: 20%;
    border-bottom: 3px solid #9a00df;
    padding-block: 15px;
}
.meter-num p{
    font-weight: 600 ;
    font-family: sans-serif;
    margin-top: 4px !important;
    font-size: 13px;
    color: #00c442;

}
.start-metric-sec h6{
    font-family: 'Outfit', sans-serif;
    padding-block: 42px;
    margin-bottom: 0px;
}
.modal-bet button{
    background-color: transparent;
    border: none;
}

.modal-bet-filter i{   
    font-weight: 400;
    font-size: 24px;
    margin-right: 20px;
}

.modal-bet-filter h5{   
    font-family: 'Outfit', sans-serif;  
    font-weight: 600;
    color:#0e2a55;
    font-size: 30px;
}
.ant-steps-label-vertical .ant-steps-item-content{
    text-align: center;
}
.ant-steps-item-title{
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon span{
    color:#9a00df !important;
    font-family: 'Outfit', sans-serif;  
    font-weight: 600;
}

.ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon{
    background-color: #eecdf8 !important;
    border: 2px solid #9a00df !important;
    color:#9a00df !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
    background-color: #eecdf8 !important;
    border: 2px solid #9a00df !important;
    color:#9a00df !important;
}
.ant-steps-label-vertical .ant-steps-item-icon{
    margin-left: 38px !important;
}
.ant-steps-item-icon{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 42px !important;  
    height: 42px !important;
}
.ant-steps-label-vertical .ant-steps-item-icon span{
    color: #3b5ea0 !important;
    font-family: 'Outfit', sans-serif;  
    font-weight: 600;
}
.ant-steps-label-vertical .ant-steps-item-icon{
    background-color: #d1d9e9 !important;
    border: none !important;
    color: #3b5ea0 !important;
}
.ant-steps-item-tail{
 top: 16px !important;
}

.ant-steps-item-tail::after{
    height: 3px !important;
}
.ant-steps-label-vertical .ant-steps-item-icon span{
    color:#9a00df !important;
    font-weight: 600 !important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after{
    background-color: #9a00df !important;
}
.metric-filter-boxes-main p{ 
    color:#34599d;
    font-family: 'Outfit', sans-serif;  
    font-weight: 600;
    font-size: 16px;
}
.metric-filter-boxes-main h6{
    color:#0e2a55;
    font-family: 'Outfit', sans-serif;  
    font-weight: 600;
    font-size: 18px;
}
.column-20-percent{
    width: 20%;
}
.steps-content-map{
    width: 100%;
    height: 300px;
    overflow-x: hidden;
    overflow-y: scroll ;
}
.steps-content-map::-webkit-scrollbar {
    display:none
  }
  
.steps-content-map .steps-content-text{
    height: 100px;
    box-shadow: var(--bx-shado);
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:black;
    font-family: 'Outfit', sans-serif;  
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    padding-inline: 5px;
}


.steps-content-text-2{
    height: 100px;
    border:  2px dashed #9a00df;
    box-shadow: var(--bx-shado);
    background-color: #eecdf8;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#9a00df;
    font-family: 'Outfit', sans-serif;  
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    padding-inline: 5px;
}


.filter_result{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-block: 50px;
}


.filter_result h1{
    color: #34599d;
    font-family: 'Outfit', sans-serif;  
    font-weight: 700;
    font-size: 45px;
    margin: 0px;
}

.filter_result h5{
    color: black;
    font-family: 'Outfit', sans-serif;  
    font-weight: 600;
    margin-bottom: 25px;
}

.platform-build-sec-stats{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-block: 10px;
}

.platform-build-sec-stats h5{
    color: black;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;  
    margin: 0px;
}
.filtraton-engine .location{
    cursor: pointer;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 5px;
}

.filtraton-engine .game_range{
    cursor: pointer;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 5px;
}

.filtraton-engine .streak{
    cursor: pointer;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 5px;

}

.filtraton-engine .team{
    cursor: pointer;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    display: flex;
    align-items: center;
    padding-block: 5px;
    justify-content: center;
}

.filtraton-engine .stats{
    cursor: pointer;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    display: flex;
    align-items: center;
    padding-block: 5px;
    justify-content: center;
}

.Location-fil-tab{
    border: 1px solid #F7F5F2;
    border-radius: 10px;
    padding-block: 15px;
    padding-inline: 10px;
    font-weight: 600;
    font-family: 'Outfit', sans-serif;
    color: black;
    display: flex;
    align-items: center;
}
input[type=radio]:checked{
    color: #1EC130 !important;
}

.Location-fil-tab input[type='radio']:after {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    top: 1px;
    left: 3px;
    position: relative;
    background-color: transparent;
    content: '';
    display: inline-block;
    visibility: visible;
    border:none;
}

.Location-fil-tab input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 14px;
    top: 1px;
    left: 2px;
    position: relative;
    background-color: #1EC130;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
.Location-fil-tab input{
    margin-right:10px;
    border: 1px solid gray;
    height: 19px;
    width: 19px;
    padding: 4px;
}

.filter-last-button button{
    width: 100%;
    border: 2px solid #9a00df;
    border-radius: 5px;
    background-color: transparent;
    padding-block: 14px;
    font-weight: 800 ;
    font-size: 15px !important;
    font-family: 'Outfit', sans-serif;
    color:#9a00df;
    cursor: pointer;
    outline: none;
}
.filter-last-button button:hover{
    background-color: transparent !important;
}
.ant-slider-handle {
    position: absolute;
    width: 25px;
    height: 25px;
    margin-top: -10px;
    background-color: #fff;
    border: solid 2px #9a00df;
    border-radius: 50%;
    box-shadow: 0;
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-track{
    background-color: #f5f5f5;
}
.ant-slider-rail{
    background-color: #9a00df;
}
.ant-slider:hover .ant-slider-rail{
    background-color: #9a00df;
}
.ant-slider:hover .ant-slider-track{
    background-color: #f5f5f5;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open){
    border: solid 2px #9a00df;
}
.ant-slider-handle:focus{
    border: solid 2px #9a00df;
}
.ant-slider-handle.ant-tooltip-open{
    border: solid 2px #9a00df;
}
.Location-fil-body label{
    margin: 0px;
    font-family: 'Outfit', sans-serif;
    font-weight: 600;
    color: black;
}
.Location-fil-body input[type=text]{
    width: 100%;
    border: 1px solid #ccd1de;
    padding-inline: 10px;
    padding-block: 5px;
    border-radius: 10px;
    outline: none;
    background-color: #f8f5f2;
}
.your_strategy_last_form{
    color: #0e2a55;
    font-weight: 600;
    padding-block: 20px;
    margin: 0px;
}
.platform-build-sec-filter spaning{
    border: 1px solid #0e2a55;
    color:#0e2a55;
    font-family: 'Outfit', sans-serif;
    font-size: 12px;
    padding-inline: 8px;
    border-radius: 20px;
    padding-block: 0px;
    display:flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    background-color: #d1d9e9;
    margin-block: 8px;
}
.platform-build-sec-filter spaning i{
    margin-left: 7px;
    font-weight: 300;
}
.last_simple_base button{
    width: 100%;
    border:2px solid #a2b1d1;
    border-radius: 5px;
    color: #a2b1d1;
    font-weight: 600;
    background-color: white;
    font-size: 14px !important;
    margin-block: 10px;
    padding-block: 10px;
    outline: none;
}
.last_simple_base button:hover{
    width: 100%;
    border:2px solid #9a00df !important;
    border-radius: 5px;
    color: #9a00df !important;
    font-weight: 600;
    background-color: white !important;
    padding-block: 10px;
}
.history_down{
    height: 35px;
    background-color: white;
    width:100%;
    border-radius: 5px;
    box-shadow: 0px 8px 16px rgb(109 141 173 / 15%);

}

.history_down p{
    margin: 0px;
    margin-left: 10px;
}

.anti-map-chart-date .starategy-card-time-zone .Date-format{
    width: 119px;
    margin-inline: 0px !important;
}

.anti-map-chart-date label{
    margin: 0px;
    font-family: 'Outfit', sans-serif;
    font-weight: 600;
    font-size: 12px;
    color:#0e2a55;
}
@media (min-width: 576px){
    body .modal-dialog{
        max-width: fit-content !important;
        margin: 1.75rem auto;
    }
    .modal-dialog {
        max-width: fit-content;
        margin: 1.75rem auto;
    }
}