@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root{
    --bx-shado: 0px 8px 16px 0px #6D8DAD26;
}


.slide-card{
    height: 130px;
    width: 100%;
    border-radius: 10px;
    box-shadow: var(--bx-shado);
}
.slide-card .card-date p{
  font-family: 'Outfit', sans-serif;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0px;
}
.card-img-and-name {
    display: flex;
    align-items: center;
}
.card-img-and-name .card-image{
    width: 24px;
    height: 24px;
    border: 1px solid #F7F5F2;
    border-radius: 5px;
}
.card-img-and-name h5{
    font-family: 'Outfit', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    color: #000000;
    margin-bottom: 0px;
    margin-left: 5px;
    margin-top: 3px;
}
.card-score h5{
    font-family: 'Outfit', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 140%;
    color: #000000;
    margin: 0px;
}
.card-score .scrore-1{
    box-shadow: var(--bx-shado);
    border-radius: 5px;
    padding-inline: 10px;
    padding-block: 5px;
    margin-left: 5px;
}
#dashboard .ant-carousel .slick-prev, .ant-carousel .slick-prev {
    position: absolute;
    top: 46%;
    display: block;
    width: 40px !important;
    height: 40px !important;
    margin-top: -10px;
    padding: 0;
    color: transparent;
    font-size: 0;
    line-height: 0;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
}


#dashboard .ant-carousel .slick-prev, .ant-carousel .slick-next {
    position: absolute;
    top: 46%;
    display: block;
    width: 40px !important;
    height: 40px !important;
    margin-top: -10px;
    padding: 0;
    color: transparent;
    font-size: 0;
    line-height: 0;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
}

.slider-content .ant-carousel .slick-prev {
    left: -14px !important;
    z-index: 99 !important;
}
.slider-content .ant-carousel .slick-prev {
    background: #FFFFFF !important;
    border: 2px solid rgba(24, 41, 85, 0.5) !important;
    border-radius: 50% !important;
}
.slider-content .slick-list{
    padding: 0px !important;
}
.slider-content .ant-carousel .slick-prev::after {
    font-family: FontAwesome;
    content: "\f177";
    position: absolute;
    top: 18px;
    left: 9px;
    color: rgba(24, 41, 85, 0.5) !important;
    z-index: 22;
    font-size: 20px !important;
}








.slider-content .ant-carousel .slick-next {
    right: 14px !important;
    z-index: 99 !important;
}
.slider-content .ant-carousel .slick-next {
    background: #FFFFFF !important;
    border: 2px solid rgba(24, 41, 85, 0.5) !important;
    border-radius: 50% !important;
}
.slider-content .slick-list{
    padding: 0px !important;
}
.slider-content .ant-carousel .slick-next::after {
    font-family: FontAwesome;
    content: "\f178 ";
    position: absolute;
    top: 18px;
    left: 9px;
    color: rgba(24, 41, 85, 0.5) !important;
    z-index: 22;
    font-size: 20px !important;
}


.ant-dropdown-trigger{
    border: 2px solid black !important;
    border-radius: 10px !important;
    padding-block: 8px !important;
}
.ant-space-item{
    padding-bottom: 0px !important;
    font-weight: 600;
}

.ant-space-align-center {
    width: 137px !important; 
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important; 
    padding-inline: 10px !important;
}
.switcher-dashboard{
    width: 360px;
    position: relative;
    height: 60px;
    border: 1px solid #F7F5F2;
    border-radius: 35px;
    display: flex;
    align-items: center;
}
.purple-dash-bacr-btn{
    border: 1px solid #F7F5F2;
    border-radius: 35px;
    width: 50%;
    position: absolute;
    background-color: #8D0AE2;
    z-index: 99;
    font-family: 'Outfit', sans-serif;
    color: white;
    padding-inline: 40px;
    padding-block: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: transformer 0.4s all;
}

.dash-bacr-btn{
    display: flex;
    align-items: center;
    font-family: 'Outfit', sans-serif;
    justify-content: center;
}
.dash-bacr-btn h5{
    margin: 0px !important;

}
.purple-dash-bacr-btn h5{
    margin: 0px !important;
    color: white;
}

.hello-user-name h1{
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 45px;
    color: #182955;
}
.Location-fil-body .ant-select{
    box-shadow: none;
}
.ant-select {
    padding: 8px 0px !important;
    background: #FFFFFF !important;
    border: 1px solid #F7F5F2 !important;
    box-shadow: 0px 8px 16px rgba(109, 141, 173, 0.15);
    border-radius: 10px !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid transparent !important;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-open{
    border: none !important;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    box-shadow: none !important;
}
.ant-select-selection-item{
    font-weight: 600;
}
.ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: 11px;
    display: flex;
    align-items: center;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 1) !important;
    font-size: 14px !important;
    line-height: 1;
    text-align: center;
    pointer-events: none;
}



.price-btn-outinle-2-2 button {
    background-color: #25408F;
    border: 2px solid #25408F;
    color: white;
    width: 100%;
    transition: 0.3s all;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    outline: none;
    padding: 12px 30px;
  }
  
  .price-btn-outinle-2-2 button:hover {
    background-color: #06216f !important;
    color: rgb(69, 166, 69);
  }
  
  .price-btn-outinle-2-2 button .animation-span-1 {
    background-color: white;
    height: 100%;
    width: 9%;
    position: absolute;
    right: 0px;
    z-index: 99;
    top: 0px;
    border-radius: 5px;
  }
  
  .price-btn-outinle-2-2 .btn-animation-arrow {
    margin-left: 10px;
    transition: 0.3s all;
  }
  
  .price-btn-outinle-2-2 button:hover .btn-animation-arrow {
    transform: translate(15px, -15px);
    opacity: 0;
  }
  
  .price-btn-outinle-2-2 button .animation-span-2 {
    background-color: white;
    height: 17px;
    z-index: 99;
    width: 100%;
    position: absolute;
    right: 0px;
    bottom: 0px;
    border-radius: 5px;
  }
  
  
  .price-btn-outinle-2-2 button:hover .btn-animation-arrow-2 {
    transform: translate(-13px, 0px);
    opacity: 1;
  }
  
  .price-btn-outinle-2-2 .btn-animation-arrow-2 {
    transform: translate(-27px, 16px);
    transition: 0.3s all;
    opacity: 0;
  }

.your-bet-sec h2{
    font-weight: 700;
    color: black;
    font-family: 'Outfit', sans-serif;
}
.your-bet-sec h5{
    font-weight: 600 ;
    color: black;
}





.strategy-section-1 h6{
    font-weight: 600;
    color: black;
    font-family: 'Outfit', sans-serif;
    padding-top: 10px;
    margin: 0px;
}

.strategy-section-1-1 h6{
    font-weight: 600;
    color: black;
    font-family: 'Outfit', sans-serif;
    padding-top: 10px;
    margin: 0px;
}

.strategy-section-2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-inline: 10px;
}

.strategy-section-2 p{ 
    font-weight: 600;
    color: black;
    font-family: 'Outfit', sans-serif;
    margin: 0px;
}

.starategy-card-time-zone p{ 
    font-style: normal;
    font-family: 'Outfit', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #182955;
    margin: 0px;
}
.starategy-card-time-zone .Date-format{
    width: 99px;
height: 28px;
border: 1px solid #B2B8CF;
border-radius: 10px;
display:flex;
align-items: center;
padding-inline: 4px;
}
.starategy-card-time-zone img{
    margin-left: 5px;
}
.strategy-last-btn{
    color: white;
    background-color:#1EC130;
    padding-inline: 10px;
    padding-block: 2px;
    font-size: 9px;
    font-family: 'Outfit', sans-serif;
    border: none;
    border-radius:5px;
}



/* ========================Scroll Cards====================== */
#scroll_cards {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row !important;
    background: radial-gradient(85% 568.89% at 96.97% 100%, #4C057A 0%, #8D0AE2 100%);
    border-radius: 20px;
  }
  .scroll_heading h2 {
    font-family: 'Outfit', sans-serif;
    font-weight: 600;
    font-size: 46px;
    color: #FFFFFF;
  }
  .scroll_card-button {
    font-family: 'Outfit', sans-serif !important;
    background: #FFFFFF !important;
    color: #8D0AE2 !important;
    font-weight: 800 !important;
    letter-spacing: 0.1em !important;
  }
  .scroll_card-button:hover {
    transition: .2s !important;
    background: #FFFFFF !important;
    color: #1EC130 !important;
  }
  .card_1-details .span_1 {
    border: 1px solid #1EC130;
    border-radius: 5px;
    color: #1EC130;
    font-size: 10.4px;
    padding: 2px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
  }
  .card1 {
    background: #FFFFFF;
    padding: 0px 0px 25px;
    box-shadow: 0px 8px 16px rgb(109 141 173 / 15%);
    border-radius: 20px;
    font-family: 'Roboto', sans-serif;
  }
  .card_1-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    font-family: 'Roboto', sans-serif;
  }
  .heading_1 {
    height: 120px;
    font-family: 'Roboto', sans-serif;
  }
  .heading_1 h6 {
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 17px;
    font-size: 24px;
    color: #000000;
    font-family: 'Roboto', sans-serif;
    transition: .2s;
  }
  .heading_1 h6:hover {
    color: #8D0AE2;
  }
  .span_2 {
    padding-top: 17px;
  }
  .over::-webkit-scrollbar {
    display: none !important;
  }
  .span_1 {
    margin-top: 25px;
    margin-left: 18px;
  }
  .span_2 span {
    font-style: normal;
    font-weight: 400;
    font-size: 10.4px;
    font-family: 'Roboto', sans-serif;
    padding-left: 18.5px;
  }




















  #DownBoard .slide-card{
    height: fit-content !important;
    width: 100%;
    border-radius: 10px;
    box-shadow: var(--bx-shado);
}
#DownBoard .strategy-section-1{
    padding-inline: 20px;
    padding-block: 10px;
    width: 33.3%;

    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #1EC130;
}
#DownBoard .strategy-section-1 .strategy-section-1-pic{
    border: 1px solid #F7F5F2;
    border-radius: 5px;
    width: 36px;
    height: 36px;
}

.strategy-section-1 h6{
    font-weight: 600;
    color: black;
    font-family: 'Outfit', sans-serif;
    padding-top: 10px;
    margin: 0px;
}
#DownBoard .slide-card{
    height: fit-content !important;
    width: 100%;
    border-radius: 10px;
    box-shadow: var(--bx-shado);
}
#DownBoard .strategy-section-1-1{
    padding-inline: 20px;
    width: 33.3%;
    padding-block: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border-radius: 5px;
}
#DownBoard .strategy-section-1-1 .strategy-section-1-pic{
    border: 1px solid #F7F5F2;
    border-radius: 5px;
    width: 36px;
    height: 36px;
}

#DownBoard .ant-carousel .slick-next {
    right: -47px !important;
    z-index: 99 !important;
    background: rgba(37, 64, 143, 0.2) !important;
    border: 2px solid rgba(24, 41, 85, 0.5) !important;
}
#DownBoard .ant-carousel .slick-prev {
    left: -47px !important;
    z-index: 99 !important;
    background: rgba(37, 64, 143, 0.2) !important;
    border: 2px solid rgba(24, 41, 85, 0.5) !important;
}
#DownBoard .ant-carousel .slick-prev {
    left: -47px !important;
    z-index: 99 !important;
    background: rgba(37, 64, 143, 0.2) !important;
    border: 2px solid rgba(24, 41, 85, 0.5) !important;
}

#DownBoard .ant-carousel .slick-prev::after {
    font-family: FontAwesome;
    position: absolute;
    content: "\f177";
    top: 19px;
    left: 11px;
    color: #7688a5;
    z-index: 22;
    font-size: 18px !important;
}

#DownBoard .ant-carousel .slick-next::after {
    font-family: FontAwesome;
    position: absolute;
    content: "\f178 ";
    top: 19px;
    left: 12px;
    color: #7688a5;
    z-index: 22;
    font-size: 18px !important;
}

































#DownBoard2 .slide-card{
    height: fit-content !important;
    width: 100%;
    border-radius: 10px;
    box-shadow: var(--bx-shado);
}
#DownBoard2 .strategy-section-1{
    padding-inline: 20px;
    padding-block: 10px;
    width: 33.3%;

    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #1EC130;
}
#DownBoard2 .strategy-section-1 .strategy-section-1-pic{
    border: 1px solid #F7F5F2;
    border-radius: 5px;
    width: 36px;
    height: 36px;
}

.strategy-section-1 h6{
    font-weight: 600;
    color: black;
    font-family: 'Outfit', sans-serif;
    padding-top: 10px;
    margin: 0px;
}
#DownBoard2 .slide-card{
    height: fit-content !important;
    width: 100%;
    border-radius: 10px;
    box-shadow: var(--bx-shado);
}
#DownBoard2 .strategy-section-1-1{
    padding-inline: 20px;
    width: 33.3%;
    padding-block: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border-radius: 5px;
}
#DownBoard2 .strategy-section-1-1 .strategy-section-1-pic{
    border: 1px solid #F7F5F2;
    border-radius: 5px;
    width: 36px;
    height: 36px;
}

#DownBoard2 .ant-carousel .slick-next {
    right: -47px !important;
    z-index: 99 !important;
    background: rgba(37, 64, 143, 0.2) !important;
    border: 2px solid rgba(24, 41, 85, 0.5) !important;
}
#DownBoard2 .ant-carousel .slick-prev {
    left: -47px !important;
    z-index: 99 !important;
    display: none !important;
    background: rgba(37, 64, 143, 0.2) !important;
    border: 2px solid rgba(24, 41, 85, 0.5) !important;
}
#DownBoard2 .ant-carousel .slick-prev {
    left: -47px !important;
    z-index: 99 !important;
    background: rgba(37, 64, 143, 0.2) !important;
    border: 2px solid rgba(24, 41, 85, 0.5) !important;
}

#DownBoard2 .ant-carousel .slick-prev::after {
    font-family: FontAwesome;
    position: absolute;
    content: "\f177";
    top: 19px;
    left: 11px;
    color: #7688a5;
    z-index: 22;
    font-size: 18px !important;
}

#DownBoard2 .ant-carousel .slick-next::after {
    font-family: FontAwesome;
    position: absolute;
    content: "\f178 ";
    top: 19px;
    left: 12px;
    color: #7688a5;
    z-index: 22;
    font-size: 18px !important;
}



















































































  #DownBoard3 .slide-card{
    height: fit-content !important;
    width: 100%;
    border-radius: 10px;
    box-shadow: var(--bx-shado);
}
#DownBoard3 .strategy-section-1{
    padding-inline: 20px;
    padding-block: 10px;
    width: 33.3%;

    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #1EC130;
}
#DownBoard3 .strategy-section-1 .strategy-section-1-pic{
    border: 1px solid #F7F5F2;
    border-radius: 5px;
    width: 36px;
    height: 36px;
}

.strategy-section-1 h6{
    font-weight: 600;
    color: black;
    font-family: 'Outfit', sans-serif;
    padding-top: 10px;
    margin: 0px;
}
#DownBoard3 .slide-card{
    height: fit-content !important;
    width: 100%;
    border-radius: 10px;
    box-shadow: var(--bx-shado);
}
#DownBoard3 .strategy-section-1-1{
    padding-inline: 20px;
    width: 33.3%;
    padding-block: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border-radius: 5px;
}
#DownBoard3 .strategy-section-1-1 .strategy-section-1-pic{
    border: 1px solid #F7F5F2;
    border-radius: 5px;
    width: 36px;
    height: 36px;
}

#DownBoard3 .ant-carousel .slick-next {
    right: -47px !important;
    z-index: 99 !important;
    background: rgba(37, 64, 143, 0.2) !important;
    border: 2px solid rgba(24, 41, 85, 0.5) !important;
}
#DownBoard3 .ant-carousel .slick-prev {
    left: -47px !important;
    z-index: 99 !important;
    background: rgba(37, 64, 143, 0.2) !important;
    border: 2px solid rgba(24, 41, 85, 0.5) !important;
}
#DownBoard3 .ant-carousel .slick-prev {
    left: -47px !important;
    z-index: 99 !important;
    background: rgba(37, 64, 143, 0.2) !important;
    border: 2px solid rgba(24, 41, 85, 0.5) !important;
}

#DownBoard3 .ant-carousel .slick-prev::after {
    font-family: FontAwesome;
    position: absolute;
    content: "\f177";
    top: 19px;
    left: 11px;
    color: #7688a5;
    z-index: 22;
    font-size: 18px !important;
}

#DownBoard3 .ant-carousel .slick-next::after {
    font-family: FontAwesome;
    position: absolute;
    content: "\f178 ";
    top: 19px;
    left: 12px;
    color: #7688a5;
    z-index: 22;
    font-size: 18px !important;
}



































#DownBoard4 .slide-card{
    height: fit-content !important;
    width: 100%;
    border-radius: 10px;
    box-shadow: var(--bx-shado);
}
#DownBoard4 .strategy-section-1{
    padding-inline: 20px;
    padding-block: 10px;
    width: 33.3%;

    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #1EC130;
}
#DownBoard4 .strategy-section-1 .strategy-section-1-pic{
    border: 1px solid #F7F5F2;
    border-radius: 5px;
    width: 36px;
    height: 36px;
}

.strategy-section-1 h6{
    font-weight: 600;
    color: black;
    font-family: 'Outfit', sans-serif;
    padding-top: 10px;
    margin: 0px;
}
#DownBoard4 .slide-card{
    height: fit-content !important;
    width: 100%;
    border-radius: 10px;
    box-shadow: var(--bx-shado);
}
#DownBoard4 .strategy-section-1-1{
    padding-inline: 20px;
    width: 33.3%;
    padding-block: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    border-radius: 5px;
}
#DownBoard4 .strategy-section-1-1 .strategy-section-1-pic{
    border: 1px solid #F7F5F2;
    border-radius: 5px;
    width: 36px;
    height: 36px;
}

#DownBoard4 .ant-carousel .slick-next {
    right: -47px !important;
    z-index: 99 !important;
    background: rgba(37, 64, 143, 0.2) !important;
    border: 2px solid rgba(24, 41, 85, 0.5) !important;
}
#DownBoard4 .ant-carousel .slick-prev {
    left: -47px !important;
    z-index: 99 !important;
    display: none !important;
    background: rgba(37, 64, 143, 0.2) !important;
    border: 2px solid rgba(24, 41, 85, 0.5) !important;
}
#DownBoard4 .ant-carousel .slick-prev {
    left: -47px !important;
    z-index: 99 !important;
    display: none !important;
    background: rgba(37, 64, 143, 0.2) !important;
    border: 2px solid rgba(24, 41, 85, 0.5) !important;
}

#DownBoard4 .ant-carousel .slick-prev::after {
    font-family: FontAwesome;
    position: absolute;
    content: "\f177";
    top: 19px;
    left: 11px;
    color: #7688a5;
    z-index: 22;
    font-size: 18px !important;
}

#DownBoard4 .ant-carousel .slick-next::after {
    font-family: FontAwesome;
    position: absolute;
    content: "\f178 ";
    top: 19px;
    left: 12px;
    color: #7688a5;
    z-index: 22;
    font-size: 18px !important;
}
